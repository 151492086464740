import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Alert } from '@mui/material';

function LoginPage({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    // Predefined username and password
    if (username === 'DialogIQ' && password === 'pass123') {
      onLogin(true);
      navigate('/');
    } else {
      setError('Wrong user name or password');
    }
  };

  return (
    <Container maxWidth="xs" style={{ marginTop: '100px' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        DialogIQ
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <TextField
        label="User Name"
        variant="outlined"
        fullWidth
        margin="normal"
        value={username}
        onChange={e => setUsername(e.target.value)}
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        margin="normal"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Button 
        onClick={handleLogin} 
        variant="contained" 
        color="primary" 
        fullWidth
        style={{ marginTop: '16px' }}
      >
        Login
      </Button>
    </Container>
  );
}

export default LoginPage;
