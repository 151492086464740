import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import MuiAppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from "react-router-dom";
import routes from "../../routes";
import UserProfile from './UserProfile';
import "./CollapsibleSidebar.scss";

const drawerWidth = 260;
const currentVersion = 'Version: 1.0.0-beta.3';

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: 0,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  [theme.breakpoints.down('sm')]: {
    minHeight: '48px', 
  },
  [theme.breakpoints.up('sm')]: {
    minHeight: '48px', 
  },
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#f4f7fd',
    boxShadow: 'none',
    border: '1px solid #e0e0e0',
  }));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    minHeight: '48px', 
  },
  [theme.breakpoints.up('sm')]: {
    minHeight: '48px',
  },
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function CollapsibleSidebar(props) {
  const { onClose, drawerWidth, setViews } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDrawerStatus = () => {
    setOpen(!open);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null); 
  };

  return (
    <Box sx={{ display: 'flex' , backgroundColor: '#f4f7fd'}}>
      <CssBaseline />
      <AppBar position="fixed">
        <CustomToolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerStatus}
            edge="start"
            sx={{
              marginRight: 5,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ color: 'black' }}>
            {process.env.REACT_APP_TITLE || "DialogIQ"}
          </Typography>
          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center'}}>
            <Stack direction="row" spacing={1} alignItems="center">
            <IconButton 
              aria-label="settings"
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              <MailIcon />
            </IconButton>
            <IconButton 
              aria-label="settings"
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              <NotificationsIcon />
            </IconButton>
            <IconButton 
              aria-label="settings"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleAvatarClick}
            >
              <AccountCircle />
            </IconButton>
            </Stack>
          </div>
        </CustomToolbar>
      </AppBar>
      <SwipeableDrawer
        open={open}
        onClose={handleDrawerStatus}
        onOpen={handleDrawerStatus}
        disableScrollLock={true}
        sx={{
          '& .MuiDrawer-paper': {
            width: {
              xs: '50%',
              md: '50%'
            }
          }
        }}
      >
        <DrawerHeader sx={{ padding: '25px'}}>
          <img className='connex-logo' src={process.env.REACT_APP_SIDEBAR_LOGO_SRC} alt="Connex Logo" />
        </DrawerHeader>
        <Divider />
        <List>
          {routes.map((route, index) => (
            <Link key={index} onClick={() => {setOpen(false); setViews(route.path);}} className='link-container'>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText primary={route.name} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
        <Typography 
        variant='body2' 
        sx={{ position: 'absolute', bottom: '1rem', left: '1rem'}}
      > {currentVersion} </Typography>
        <Divider />
      </SwipeableDrawer>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        disableScrollLock={true}
      >
        <MenuItem sx={{ pointerEvents: 'none' }}>
          <UserProfile />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}
