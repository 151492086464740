import React, { useState, useEffect, useCallback } from 'react';
import { convertRawJsonToLayoutJson, convertStringToText, renderConfigLayout } from '../utils/common'
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { FormControl, InputLabel, Table, TableHead, TableRow, TableCell, TableBody, TextField, Pagination, Button, OutlinedInput, InputAdornment, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Chip } from '@mui/material';
import RawJsonView from './RawJsonView';
import Typography from '@mui/material/Typography';
import './styles/v2.scss';
import EditIcon from '@mui/icons-material/Edit';
import httpService from '../api/http.service';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import configLayoutDemoNlu from "../config/configLayoutDemoNlu.json";
import routes from '../routes';

const VIEWS = {
  GUI: 'gui',
  JSON: 'json'
}

const GRAPH = {
  GRAPH: 'graph',
  D3GRAPH: 'd3graph'
}

const Configurations = (props) => {
  const {
    configJson,
    setConfigJson,
    configLayout,
    setConfigLayout,
    graphStatus,
    setGraphStatus,
    setViews
  }=props;


  const pageSize = 4;
  const [selectedConfigPreset, setSelectedConfigPreset] = useState(""); 
  const [view, setView] = useState(VIEWS.GUI);
  const [pageIndex, setPageIndex] = useState(0);
  const [configs, setConfigs] = useState([]);
  const [configName, setConfigName] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [open, setOpen] = useState(false);
  const [ViewId, setViewId] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [runId, setRunId] = useState('');


  const setFilter = (e)=> {
    setFilterValue(e.target.value);
  }

  const updateConfigName = (e)=> {
    setConfigName(e.target.value);
  }

  const startSearch = ()=> {
    setSearchText(filterValue);
  }

  const loadConfigs = async()=> {
    const {data} = await httpService.get(`/get-configurations`);
    setConfigs(data)
    setConfigLayout(configLayoutDemoNlu)
  }

  useEffect(() => {
    loadConfigs();
  }, []);

  useEffect(() => {
    sessionStorage.setItem('configJson', typeof configJson === 'object' ? JSON.stringify(configJson) : configJson);

    if(configJson && runId) {
      if(configJson["option"] === "directed_dialogue_crawler" || configJson["option"] === "natural_language_crawler") {
        if(configJson["nlu_intents"]) {
          setViews('run');
        }
      } else {
        setViews('run');
      }
    }
  }, [configJson,runId]);

// for debug purpose only
  useEffect(() => {
    sessionStorage.setItem('configLayout', JSON.stringify(configLayout)); 
  }, [configLayout]);

  useEffect(() => {
    const newConfigLayout = configs.find(config => config.id === selectedConfigPreset)?.value;
    const newGraph = configs.find(config => config.id === selectedConfigPreset)?.defaultGraph ?? undefined;
    setConfigName('');

    if (newConfigLayout) {
      setConfigLayout(newConfigLayout);
    }

    if (newGraph) {
      setGraphStatus(newGraph);
    }
  }, [selectedConfigPreset, configs]);

  const run = (id)=> {
    setRunId('');
    setConfigJson("");
    setConfigLayout("");
    setRunId(id);
 
    setSelectedConfigPreset(id);
  }

  const handleConfigChange = (id) => {
    setRunId('');
    setConfigJson("");
    setConfigLayout("");
    setSelectedConfigPreset(id);
  };

  const handleChangeView = () => {
    let _copyConfigJson
    if(view === VIEWS.GUI){
      _copyConfigJson = {...configJson}
    }
    if (view === VIEWS.JSON) {
      try {
        const validatedJson = JSON.parse(configJson);
        validatedJson && setConfigJson(validatedJson);
        setConfigLayout(convertRawJsonToLayoutJson(validatedJson));
      } catch (err) {
        alert('Invalid JSON. No changes will be saved.');
        setConfigJson(_copyConfigJson);
      }
    }
    setView((prevView) => prevView === VIEWS.GUI ? VIEWS.JSON : VIEWS.GUI);
  };

  const handleChangeGraph = () => {
    setGraphStatus((prevView) => prevView === GRAPH.D3GRAPH ? GRAPH.GRAPH : GRAPH.D3GRAPH);
  };

  const handleChange = (event, value) => {
    setPageIndex(value-1);
  };



  const saveData = async ()=> {
    await httpService.post(`/update-configuration`, {
      id:selectedConfigPreset || configName,
      value:configLayout,
    });
    await loadConfigs();
  }

  const cancelSave = ()=> {
    setRunId('');
    setSelectedConfigPreset('');
    setConfigName('');
    setConfigLayout(
      configLayout?.map(c=>{
        const value = {
          ...c,
          content:{
            ...c.content,
            [c.type === 'table' ? 'rows':'value']: ['table',"tags",'dropdown'].includes(c.type)  ? []:'',
          }
        };
        return value;
      })
    )
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteRow = (id)=> {
    setDeleteId(id);
    setOpen(true);
  }


  const deleteRow = useCallback(async ()=> {
    await httpService.delete(`/delete-configuration?id=${deleteId}`);
    loadConfigs();
    setOpen(false);
  },[deleteId]);

  const toggleViews = () => {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Graph</Typography>
          <Switch checked={graphStatus === GRAPH.D3GRAPH} onChange={handleChangeGraph} />
        <Typography>D3 Graph</Typography>

        <Typography>JSON</Typography>
          <Switch checked={view === VIEWS.GUI} onChange={handleChangeView} />
        <Typography>GUI</Typography>
      </Stack>
    );
  };
  
  const openView = (id)=> {
    if(ViewId === id) {
      setViewId('')
    } else {
      setViewId(id)
    }
  }


  const RenderValue = ({value})=> {
    if(value['type'] === 'dropdown') {
      return <strong>{convertStringToText(value.content?.value?.[0])}</strong>
    }

    if(value['type'] === 'tags') {
      return <div className='chip-container'>{value.content?.value?.map(c=>(<Chip label={convertStringToText(c)}  />))}</div>
    }


    return <strong>{value.content?.value}</strong>
  }

  const formatTimestamp = (timestamp) => {
    const date = timestamp ? new Date(timestamp) : new Date('1970/01/01 12:00 AM');
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2').replace(',', '');
  };

  return (
    <div className='configuration__container'>
      <div className="page-title">
          <div className='config-page-header'>
            <span>Configuration </span>
            <div>
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Filter</InputLabel>
              <OutlinedInput
                onChange={setFilter}
                id="outlined-adornment-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={startSearch}
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                    <FilterAltOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Filter"
              />
            </FormControl>
            </div>
          </div>
        </div>
       
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date & Time  </TableCell>
            <TableCell>Config Name </TableCell>
            <TableCell>Last Run</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {(configs || []).filter(c=>!searchText || c.id.toLowerCase().indexOf(searchText.toLowerCase())!==-1).slice(pageIndex*pageSize,(pageIndex*pageSize)+pageSize).map((config, index) => (
           <React.Fragment key={index}>
           <TableRow >
            <TableCell>{formatTimestamp(config.timeCreated)}</TableCell>
            <TableCell><div className='view-config' onClick={()=>openView(config.id)}>{config.id}</div></TableCell>
            <TableCell>{formatTimestamp(config.timeCreated)}</TableCell>
            <TableCell className="flex">
              
              <div className='switch-container'><Switch checked={config.lock} /> <Typography>Lock</Typography></div>
             
              <EditIcon className='pointer' onClick={()=>handleConfigChange(config.id)} />
              <DeleteIcon  className='pointer' onClick={()=>handleDeleteRow(config.id)}/>
              <Button variant="contained" color="primary" onClick={()=>run(config.id)}>
                           New Run
                          </Button>
            </TableCell>
          </TableRow>

          {config.id === ViewId &&  
            <TableRow className='config-detail-view'>
              <TableCell colSpan={4}>
                <div className='grid'>
                  {configs.find(config => config.id === ViewId)?.value?.filter(v=>!['table','input-group','select-cards'].includes(v['type']))?.map(v=>(
                    <div>
                      {convertStringToText(v.content.key)} : <RenderValue value={v} />
                    </div>
                  ))}
                </div>
              </TableCell>
            </TableRow>}
          </React.Fragment>
        ))}
         
        </TableBody>
      </Table>
      <div className='pagination-container'>
        <Pagination count={Math.ceil(configs.filter(c=>!searchText || c.id.toLowerCase().indexOf(searchText.toLowerCase())!==-1).length/pageSize)} showFirstButton showLastButton page={pageIndex+1} onChange={handleChange}/>
      </div>
      <div className='config-details'>
      {selectedConfigPreset ? 'Edit':'New'} Configuration 
      </div>
      
      <div className='config-form'>
        <TextField
          label="Config Name"  
          onChange={updateConfigName}
          value={selectedConfigPreset || configName}
          style={{marginBottom:12,width:'99%',marginLeft:6}}
        />
        { view === VIEWS.GUI && 
          Object.entries(configLayout).map(([key, value], index) => {
            return renderConfigLayout({key, value, index, configJson, setConfigJson, setConfigLayout})
          }
        )}
        {
          view === VIEWS.JSON && (
            <RawJsonView
              configJson={configJson}
              setConfigJson={setConfigJson}
              setConfigLayout={setConfigLayout}
            />           
          )
        }

        <div className='action-button'>
          <Button onClick={saveData} variant="contained" color="primary">Save</Button>
          {selectedConfigPreset && <Button onClick={cancelSave} variant="outlined"  color="secondary">Cancel</Button>}

        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the config?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined' color="secondary">No</Button>
          <Button onClick={deleteRow} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Configurations;
